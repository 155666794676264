@import '../../styles/colors.scss';

.about-full {
  .details {
    margin-bottom: 2rem;

    .intro {
      font-family: 'Primary', 'sans-serif';
      font-size: 1.125rem;
    }

    .socials {
      a {
        i {
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }

  .a-image {
    filter: grayscale(80%);
    text-align: center;
    transition: all ease-in-out .6s;

    &:hover {
      filter: grayscale(0%);
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }

    img {
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);;
      width: 80%;
    }
  }
}

.recommendations {
  p {
    margin: 0 auto;
  }

  .name {
    font-family: 'Primary', 'sans-serif';
    font-size: 1.125rem;
    font-weight: 500;
  }

  .role {
    color: $text-grey;
    font-size: .875rem;
    margin-top: .3rem;
  }
}

.about-partial {
  a.read-more {
    letter-spacing: 2px;
  }
}