/* css font-face for all react-web-vector-icons fonts */
@font-face{font-family:AntDesign;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/AntDesign.ttf) format('truetype')}
@font-face{font-family:Entypo;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Entypo.ttf) format('truetype')}
@font-face{font-family:EvilIcons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/EvilIcons.ttf) format('truetype')}
@font-face{font-family:Feather;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Feather.ttf) format('truetype')}
@font-face{font-family:FontAwesome;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/FontAwesome.ttf) format('truetype')}
@font-face{font-family:Foundation;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Foundation.ttf) format('truetype')}
@font-face{font-family:Ionicons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Ionicons.ttf) format('truetype')}
@font-face{font-family:MaterialCommunityIcons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/MaterialCommunityIcons.ttf) format('truetype')}
@font-face{font-family:MaterialIcons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/MaterialIcons.ttf) format('truetype')}
@font-face{font-family:Octicons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Octicons.ttf) format('truetype')}
@font-face{font-family:SimpleLineIcons;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/SimpleLineIcons.ttf) format('truetype')}
@font-face{font-family:Zocial;font-style:normal;font-weight:400;src:url(../node_modules/react-web-vector-icons/fonts/Zocial.ttf) format('truetype')}

/* font-faces */
@font-face {font-family:'Ceemion';font-style:normal;font-weight:normal;src:url(./assets/fonts/BilboSwashCaps/BilboSwashCaps-Regular.ttf) format('truetype');}

@font-face {font-family:'Primary';font-style:normal;font-weight:normal;src:url(./assets/fonts/New-York/NewYorkSmall-Regular.otf) format('opentype');}
@font-face {font-family:'Primary';font-style:italic;font-weight:normal;src:url(./assets/fonts/New-York/NewYorkSmall-RegularItalic.otf) format('opentype');}
@font-face {font-family:'Primary';font-style:normal;font-weight:500;src:url(./assets/fonts/New-York/NewYorkSmall-Medium.otf) format('opentype');}
@font-face {font-family:'Primary';font-style:italic;font-weight:500;src:url(./assets/fonts/New-York/NewYorkSmall-MediumItalic.otf) format('opentype');}

@font-face {font-family:'OpenSans';font-style:normal;font-weight:300;src:url(./assets/fonts/Open-Sans/OpenSans-Light.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:italic;font-weight:300;src:url(./assets/fonts/Open-Sans/OpenSans-LightItalic.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:normal;font-weight:normal;src:url(./assets/fonts/Open-Sans/OpenSans-Regular.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:italic;font-weight:normal;src:url(./assets/fonts/Open-Sans/OpenSans-Italic.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:normal;font-weight:500;src:url(./assets/fonts/Open-Sans/OpenSans-SemiBold.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:italic;font-weight:500;src:url(./assets/fonts/Open-Sans/OpenSans-SemiBoldItalic.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:normal;font-weight:bold;src:url(./assets/fonts/Open-Sans/OpenSans-Bold.ttf) format('truetype');}
@font-face {font-family:'OpenSans';font-style:italic;font-weight:bold;src:url(./assets/fonts/Open-Sans/OpenSans-BoldItalic.ttf) format('truetype');}

html {
  scroll-behavior: smooth;
}

html,
body {
  color: #4a4a4a;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multiline-text {
  white-space: pre-wrap;
}

a {
  color: #003963;
}

.modal-backdrop.show {
  backdrop-filter: blur(100px);
  opacity: .8;
}

.modal .modal-content {
  border-radius: .625rem;
}

.modal .modal-content .modal-controls {
  padding: 1rem;
}

.modal .modal-content .modal-controls .close {
  font-family: 'OpenSans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 2px;
}

.certificate-modal .image img {
  width: 100%;
}

.certificate-modal .url {
  text-align: center;
}
