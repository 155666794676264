$white: #ffffff;
$black: #000000;

$text: #4a4a4a;
$text-grey: #868e96;
$text-light-grey: #c0c9d2;

$grey-f7: #f7f7f7;
$dark-grey: #6D7278;

$primary: #003963;

$andela: #1433c4;
$andela-orange: #ffaf30;

$happymoney-yellow: #ffdb0a;
$happymoney-blue: #b5e3fa;
